














































































































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "protocol"
})
export default class protocol extends Vue {}
